import axios from "axios";

// Create an instance of Axios with a base URL and headers
const axiosInstance = axios.create({
    baseURL: process.env.NEXT_BACKEND_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});

// Function to set the access token
export const setAccessToken = (token) => {
    axiosInstance.defaults.headers.common['x-access-token'] = token;
}

export default axiosInstance;
